import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import style from '../styles/header_bar.module.css'
import { faArrowRightFromBracket, faBars, faUser, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import toast from 'react-hot-toast';
import { auth } from '../database';
import Logo from './Logo';
import SelecteurDeLangue from './SelecteurDeLangue'; // Import the LanguageSwitcher component

import styles from '../styles/contact.module.css'

function HeaderBar() {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [isLinksContainerOpen, setIsLinksContainerOpen] = useState(false)
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false)
    const [isNouvelles] = useState(false)

    function handleLinkClick() {
        setIsLinksContainerOpen(false);
    }

    const logOutButton = () => {
        signOut(auth)
            .then(() => {
                navigate("/admin/login/")
            }).then(() => {
                toast.success('Vous êtes déconnecté.')
            })
    }

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                setIsUserLoggedIn(true)
            }
        });
    })

    return (
        <div className={style.container}>
            <div className={style.header}>
                <Link className='global_navigation_link' onClick={() => handleLinkClick()} to={'/'}>
                    <div className={style.logo_container}>
                        <div className={style.logo}>
                            <Logo heightInPx={42} />
                        </div>
                        <span className={style.logo_text}>{t("components.headerbar.titre")}
                          <br/>
                        <span className={style.logo_sous_text}>{t("components.headerbar.sous_titre")}</span></span>
                    </div>

                </Link>

                <div style={{ display: 'flex', flexDirection: 'row', gap: '20px', marginRight: '20px', alignItems: 'center', justifyContent: 'center' }}>
                    {/* Bouton deconnexion de l'admin */}
                    {isUserLoggedIn && (
                        <button
                            className='global_button'
                            id={style.logout_button}
                            onClick={logOutButton}>
                            Déconnexion <FontAwesomeIcon id={style.logout_button_icon} icon={faArrowRightFromBracket} style={{ marginLeft: '10px', marginRight: '-10px' }} />
                        </button>
                    )}

                    <SelecteurDeLangue />

                    <button
                        id={style.btn_open} className={style.btn}
                        onClick={() => setIsLinksContainerOpen(true)}>
                        <FontAwesomeIcon icon={faBars} /></button>
                </div>

            </div>


            <div className={isLinksContainerOpen ? `${style.links_container} ${style.open}` : style.links_container}>

                <button
                    id={style.btn_close} className={style.btn}
                    onClick={() => setIsLinksContainerOpen(false)}>
                    <FontAwesomeIcon icon={faXmark} />
                </button>

                <div className={style.links}>
                    <Link className='global_navigation_link' onClick={() => handleLinkClick()} to={'/'}>
                        {t("components.headerbar.accueil")}
                    </Link>
                    <br />
                    { isNouvelles && (
                      <div>
                        <Link className='global_navigation_link' onClick={() => handleLinkClick()} to={'/nouvelles'}>
                          {t("components.headerbar.nouvelles")}
                        </Link>
                        <br />
                      </div>
                    )}
                    <Link className='global_navigation_link' onClick={() => handleLinkClick()} to={'/projets'}>
                        {t("components.headerbar.projets")}
                    </Link>
                    <br />
                    <Link className='global_navigation_link' onClick={() => handleLinkClick()} to={'/services'}>
                        {t("components.headerbar.services")}
                    </Link>
                    <br />
                    <Link className='global_navigation_link' onClick={() => handleLinkClick()} to={'/equipe'}>
                        {t("components.headerbar.equipe")}
                    </Link>
                    <br />
                    <Link className='global_navigation_link' onClick={() => handleLinkClick()} to={'/contact'}>
                        {t("components.headerbar.contact")}
                    </Link>
                    <br />
                    <Link className='global_navigation_link' onClick={() => handleLinkClick()} to={'/a_propos'}>
                        {t("components.headerbar.a_propos")}
                    </Link>


                    <br />

                    {/* Lien d'accès facile au tableau de bord de l'admin */}
                    {isUserLoggedIn && (
                        <Link className='global_navigation_link' onClick={() => handleLinkClick()} to={"/admin"}>
                            <FontAwesomeIcon id={style.logout_button_icon} icon={faUser} style={{ paddingRight: '20px', fontSize: '1.6rem' }} />
                            Tableau de bord
                        </Link>

                    )}
                </div>
            </div>

        </div>
    );
}

export default HeaderBar;
