import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from '../styles/carte_employe_equipe.module.css'
import { faExternalLink } from '@fortawesome/free-solid-svg-icons';

interface Props {
    titre: string,
    role: string,
    description: string,
    image: string,
    url: string
}

function addHttps(url: string) {
  if (!/^https?:\/\//i.test(url)) {
    url = "https://" + url;
  }
  return url;
}

function CarteEmployeEquipe(props: Props) {

    return (
        <div className={styles.card}>
          {props.url ? (
            <img className={styles.thumbnail_partenaire} src={`${props.image}`} />
          ) : (
            <img className={styles.thumbnail} src={`${props.image}`} />
          )}
            <div className={styles.texte_carte}>
                <h2 className={styles.titre}>{props.titre}</h2>
                <h4>{props.role}</h4>
                <p className={styles.description} dangerouslySetInnerHTML={{ __html: props.description.replace(/\n/g, '<br/>') }} />
                {props.url && (
                    <div>
                        <a href={ addHttps(props.url)} className='global_regular_link_light_bg' >{props.url}<FontAwesomeIcon icon={faExternalLink} style={{ paddingLeft: '5px', paddingRight: '-10px', fontSize: '0.85rem' }} /></a>
                    </div>
                )}
            </div>
        </div>
    );
}

export default CarteEmployeEquipe;
